.tableBodyRow {
  height: 48px;
  color: inherit;
  display: table-row;
  outline: none;
  vertical-align: middle;
  cursor: pointer;
  pointer-events: auto;
}

.tableCell {
  line-height: 1.42857143;
  padding: 12px 8px;
  vertical-align: middle;
  font-size: 0.8125rem;
  text-align: center;
}
