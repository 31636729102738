#change {
  margin-right: 60px;
}

#newChange {
  height: 200px;
}
.iconStyles {
  width: 56px;
  height: 56px;
}
.textStyles {
  text-align: center;
  font-weight: bold;
  font-size: 30px;
  display: inline-block;
  margin-top: 10px;
}
